import React, { useEffect, useState } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import '../STYLESHEETS/Blog.css'
import logo from '../PHOTOS/stock.png'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link, useNavigate } from 'react-router-dom'
import blog1img1 from '../PHOTOS/MAIN/tax2.jpeg'
// 
import blog2img1 from '../PHOTOS/MAIN/tax6.jpeg'
// 
import blog3img1 from '../PHOTOS/MAIN/tax5.jpeg'
// 
import { setBlogPostState } from '../REDUX/SLICES/BlogPostSlice'
import { useDispatch } from 'react-redux'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { Helmet } from 'react-helmet'
import { c_helmet, c_mainURL, c_routes } from '../Constants'

export default function Blog() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const testBlogs = [
        {
            id: 2,
            Date: "May 10, 2023",
            Title: "2023 Tax Changes: What You Need to Know for Your Business",
            Desc: "",
            ImgPath: blog1img1,
            Author: "Hilda Aranda",
            Tags: "Tax, Business, Bookkeeping", HTML:
                <div className='blogpost-wrap'>
                    <p>
                        OAs a business owner, it's important to stay up-to-date on tax changes that may affect your business. With the new year comes new tax changes that may impact your finances. Here's what you need to know about the 2023 tax changes:
                    </p>
                    <img src={blog1img1} className="blogpost-img" alt='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                    <h3>Tax Rates</h3>
                    <p>
                        The tax rates for corporations and small businesses will remain the same as the previous year, with a top rate of 21%. However, individual tax rates may change, so it's important to check with your tax advisor to see how this may affect your business.
                    </p>
                    <br />
                    <h3>Capital Gains Tax</h3>
                    <p>
                        There may be changes to capital gains tax rates, which could impact your business if you sell assets or invest in stocks. Be sure to talk to your tax advisor about how these changes may affect your business.
                    </p>
                    <br />
                    <h3>Deductions</h3>
                    <p>
                        There may be changes to certain business deductions, such as the home office deduction, business meals deduction, and other expenses. Make sure to keep accurate records of your expenses and consult with your tax advisor to see how these changes may affect your business.
                    </p>
                    <br />
                    <h3>Credits</h3>
                    <p>
                        There may be new tax credits available for businesses that invest in renewable energy, research and development, and other areas. Be sure to talk to your tax advisor about these potential credits and how they may benefit your business.
                    </p>
                    <br />
                    <h3>International Taxes</h3>
                    <p>
                        There may be changes to international taxes, such as the foreign tax credit, which could affect businesses with international operations. Be sure to talk to your tax advisor to see how these changes may affect your business.
                    </p>
                    <br />
                    <p>
                        In conclusion, it's important to stay up-to-date on tax changes that may affect your business. By understanding the 2023 tax changes, you can better plan for your business's financial future and avoid any potential tax penalties. Be sure to talk to your tax advisor to see how these changes may affect your business and what steps you can take to minimize their impact.
                    </p>
                    <br />
                    <p>

                    </p>
                </div>
        },
        {
            id: 1,
            Date: "May 10, 2023",
            Title: "How Outsourcing Your Bookkeeping Can Save Your Business Time and Money",
            Desc: "Outsourcing bookkeeping tasks can save businesses time and money, as it is a cost-effective solution that provides expert advice, increases accuracy, and offers scalability. Business owners can focus on growing their business while trained professionals manage their finances.",
            ImgPath: blog2img1,
            Author: "Hilda Aranda",
            Tags: "Outsourcing, Bookkeeping, Finance", HTML:
                <div className='blogpost-wrap'>
                    <p>
                        Outsourcing your bookkeeping might seem like an unnecessary expense, but it can actually save your business both time and money in the long run. As a business owner, it's important to focus on the core operations of your business, rather than spending hours on bookkeeping tasks. Here are some ways outsourcing your bookkeeping can benefit your business:
                    </p>
                    <img src={blog2img1} className="blogpost-img" alt='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                    <h3>Reduce Costs</h3>
                    <p>
                        Outsourcing your bookkeeping can be cost-effective, as you won't have to hire a full-time employee to manage your finances. This can save you money on salaries, benefits, and office space.
                    </p>
                    <br />
                    <h3>Increase Accuracy</h3>
                    <p>
                        Professional bookkeepers are trained to accurately manage your finances, which can help prevent errors and inaccuracies in your financial records. This can save you money in the long run by avoiding costly mistakes, such as tax penalties and incorrect financial statements.
                    </p>
                    <br />
                    <h3>Save Time</h3>
                    <p>
                        Outsourcing your bookkeeping can save you time that you can then allocate to growing your business. A professional bookkeeper can manage your financial records, prepare reports, and handle other financial tasks, allowing you to focus on other important aspects of your business.
                    </p>
                    <br />
                    <h3>Expert Advice</h3>
                    <p>
                        A professional bookkeeper can provide you with expert advice on financial matters, such as tax planning, cash flow management, and budgeting. This can help you make informed decisions about your business, which can save you money in the long run.
                    </p>
                    <br />
                    <h3>Scalability</h3>
                    <p>
                        As your business grows, your financial needs will also grow. Outsourcing your bookkeeping can provide you with a scalable solution that can adapt to your business needs, without the need to hire additional staff.
                    </p>
                    <br />
                    <p>
                        In conclusion, outsourcing your bookkeeping can save your business both time and money, while also providing you with expert financial advice and a scalable solution. If you're looking to streamline your finances and focus on growing your business, outsourcing your bookkeeping might be the solution you need.
                    </p>
                    <br />
                    <p>

                    </p>
                </div>
        },

        {
            id: 0,
            Date: "May 10, 2023",
            Title: "5 Compelling Reasons Why Every Business Needs a Website in 2023",
            Desc: "Discover the top 5 reasons why having a website is crucial for business success in 2023. From boosting brand credibility to driving sales, we'll explore the many benefits of having an online presence. Whether you're a small business or a large corporation, a website is a must-have in today's digital world.",
            ImgPath: blog3img1,
            Author: "Hilda Aranda",
            Tags: "Websites, Business, Presence", HTML:
                <div className='blogpost-wrap'>
                    <p>
                        As the world becomes increasingly digital, having a strong online presence is no longer optional for businesses. One of the most important elements of your online presence is your website. A website serves as the digital storefront for your business and is often the first point of contact between you and potential customers. Here are five compelling reasons why every business needs a website in 2023:
                    </p>
                    <img src={blog1img1} className="blogpost-img" alt='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                    <h3>Boost Your Brand's Credibility</h3>
                    <p>
                        A website adds legitimacy to your business and provides a platform to showcase your brand's personality, values, and mission. Having a professional website that reflects your brand identity can help establish trust and credibility with potential customers.
                    </p>
                    <br />
                    <h3>Reach a Wider Audience</h3>
                    <p>
                        A website allows you to expand your reach beyond your local community and attract customers from around the world. With search engine optimization (SEO) techniques, your website can rank higher in search engine results pages, making it easier for potential customers to find you.
                    </p>
                    <br />
                    <h3>Build Customer Relationships</h3>
                    <p>
                        A website provides a platform to engage with your customers and build relationships with them. You can use your website to provide valuable information, offer promotions, and gather feedback from your customers.
                    </p>
                    <br />
                    <h3>Showcase Your Products and Services</h3>
                    <p>
                        A website is an excellent way to showcase your products and services, and to provide potential customers with a detailed description of what you offer. You can include photos, videos, and customer reviews to help people understand the benefits of your products or services.
                    </p>
                    <br />
                    <h3>Increase Sales</h3>
                    <p>A website can help drive sales for your business by providing an online store, where customers can browse and purchase your products at their convenience. You can also use your website to collect customer information, such as email addresses, to build your customer base and market your products or services.</p>
                    <br />
                    <p>
                        In conclusion, having a website is crucial for businesses in 2023. It not only helps you establish credibility and reach a wider audience, but it also enables you to build relationships with customers, showcase your products and services, and increase sales. So, if you don't have a website for your business yet, it's time to start building one today!
                    </p>
                    <br />
                    <p>

                    </p>
                </div>
        },
    ]
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }
    const [firstBlog, setFirstBlog] = useState({})
    const [tempBlogs, setTempBlogs] = useState([])

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        testBlogs.reverse()
        setFirstBlog(testBlogs[0])
        setTempBlogs(testBlogs)
        firebaseGetPageViews({ Name: "Blog", Views: 0 })
    }, [])
    return (
        <div className='main'>
            {c_helmet("Blog", c_routes.blog)}
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="blog font1">
                <h1 className='page-title font2'>Blog</h1>
                <div className='blog-wrap'>
                    <div className='blog-left'>
                        <div className='first-blog-block' onClick={() => {
                            dispatch(setBlogPostState(firstBlog));
                            navigate('/blogpost')
                        }}>
                            <img src={firstBlog.ImgPath} className="first-blog-img" />
                            <div className='first-blog-text'>
                                <p className='first-blog-date'>{firstBlog.Date}</p>
                                <h1 className='first-blog-title font2'>{firstBlog.Title}</h1>
                                <p className='first-blog-desc'>{firstBlog.Desc}</p>
                                <div className='first-blog-tags'>
                                    {/* {
                                        firstBlog.Tags.length > 0 ?
                                            firstBlog.Tags.split(",").map((tag, j) => {
                                                return (
                                                    <p key={j} className='first-blog-tag border2'>{tag}</p>
                                                )
                                            }) : <div></div>
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='blog-right'>
                        {
                            tempBlogs.map((blog, i) => {
                                if (i > 0) {
                                    return (
                                        <div key={i} className='blog-block' onClick={() => {
                                            dispatch(setBlogPostState(blog));
                                            navigate('/blogpost')
                                        }}>
                                            <img src={blog.ImgPath} className="blog-img" />
                                            <div className='blog-text'>
                                                <p className='blog-date'>{blog.Date}</p>
                                                <h1 className='blog-title font2'>{blog.Title}</h1>
                                                <p className='blog-desc'>{blog.Desc}</p>
                                                <div className='blog-tags'>
                                                    {
                                                        blog.Tags.split(",").map((tag, j) => {
                                                            return (
                                                                <p key={j} className='blog-tag border2'>{tag}</p>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
