import React, { useEffect } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import '../STYLESHEETS/About.css'
import logo from '../PHOTOS/stock.png'
import img1 from '../PHOTOS/MAIN/May172023/tax2.jpeg'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link } from 'react-router-dom'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { c_helmet, c_routes } from '../Constants'

export default function About() {
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "About", Views: 0 })
    }, [])
    return (
        <div className='main'>
            {c_helmet("About", c_routes.about)}
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="about font1">
                <h1 className='page-title font2'>About Us</h1>
                <div className='about-wrap'>
                    <div className='about-section'>
                        <div className='about-top'>
                            <h2 className='about-sub all-caps font2'>A&A<br/>Bookkeeping</h2>
                            <img src={img1} className="about-img" />
                        </div>
                        <div className='about-text'>
                            Welcome to A & A Bookkeeping, your trusted partner in all things financial. With over a decade of experience, we are dedicated to providing top-notch bookkeeping and tax services to businesses of all sizes. Our team of expert accountants and tax professionals ensures that your financial records are accurate, organized, and compliant, allowing you to focus on what you do best—growing your business.<br /><br />

                            At A & A Bookkeeping, we understand that managing finances can be a daunting task for many entrepreneurs. That's why we strive to simplify the process, offering comprehensive solutions tailored to your specific needs. Whether you're a startup looking for basic bookkeeping services or an established company requiring complex tax planning strategies, we have the expertise to handle it all.<br /><br />

                            Our commitment to excellence goes beyond just number-crunching. We believe in building long-term relationships with our clients, working closely with you to understand your goals and challenges. We take the time to explain financial concepts in a clear and jargon-free manner, empowering you to make informed decisions for your business's financial health.<br /><br />

                            When you choose A & A Bookkeeping, you can expect accuracy, reliability, and confidentiality. We utilize the latest technology and industry best practices to ensure that your financial information is secure and up to date. Our flexible service options and competitive pricing make us the go-to choice for businesses in need of expert bookkeeping and tax assistance.<br /><br />

                            Simplify your finances and unlock your business's full potential with A & A Bookkeeping. Contact us today to schedule a consultation and discover how we can help you achieve financial success. Trust us to handle the numbers while you focus on what you love—building your business.






                        </div>
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
