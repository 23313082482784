import React, { useEffect, useState } from 'react'
// 
import '../STYLESHEETS/Services.css'
// 
import logo from '../PHOTOS/stock.png'
import img1 from '../PHOTOS/MAIN/tax4.jpeg'
import img2 from '../PHOTOS/MAIN/tax2.jpeg'
import img3 from '../PHOTOS/MAIN/tax3.jpeg'
import img4 from '../PHOTOS/MAIN/tax6.jpeg'
import img5 from '../PHOTOS/MAIN/tax5.jpeg'
//
import Navigation from './UTILITIES/Navigation'
import { RxHamburgerMenu } from 'react-icons/rx'
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs'
import Footer from './UTILITIES/Footer'
import { Link } from 'react-router-dom'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { Helmet } from 'react-helmet'
import { c_helmet, c_mainURL, c_routes } from '../Constants'
// 

export default function Services() {

    const [serviceID, setServiceID] = useState(-1)
    const services = [
        {
            id: 0,
            Name: "Bookkeeping Services", Img: img1,
            Desc: "Bookkeeping services involve recording and organizing financial transactions for a business. This can include tasks such as tracking expenses, reconciling bank statements, and maintaining financial records. These services are essential for businesses to have accurate financial information to make informed decisions.",
            Details: ""
        },
        {
            id: 1,
            Name: "Tax Preparation and Planning", Img: img2,
            Desc: "Tax preparation and planning services help businesses prepare and file their tax returns accurately and on time. Tax planning can also help businesses minimize their tax liabilities by taking advantage of available deductions and credits.",
            Details: ""
        },
        {
            id: 2,
            Name: "Payroll Services", Img: img3,
            Desc: "Payroll services involve calculating and processing employee paychecks, as well as handling payroll taxes and filings. This can be a time-consuming and complex task for businesses, so many choose to outsource this function to bookkeeping and tax services providers.",
            Details: ""
        },
        {
            id: 3,
            Name: "Consulting Services", Img: img4,
            Desc: "Bookkeeping and tax services providers can also offer consulting services to businesses, providing guidance on financial and tax matters. This can include advice on budgeting, cash flow management, tax planning strategies, and more.",
            Details: ""
        },
        {
            id: 4,
            Name: "Website Development Service", Img: img5,
            Desc: <p>You need a website that reflects your unique brand and message. <a target='_blank' href="https://wearehappycode.com/webline">Happy Code Websites & Apps</a> can create a custom website tailored to your specific needs and goals. Your website will be visually appealing, user-friendly, and optimized for search engines to help attract more traffic and potential customers.</p>,
            Details: ""
        },
    ]

    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "Services", Views: 0 })
    }, [])
    return (
        <div className='main'>
            {c_helmet("Services", c_routes.services)}
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="services font1">
                <h1 className='page-title font2'>Services</h1>
                <div className='services-split'>
                <div className='services-right'>
                        <div className='divider'></div>
                        <p>As a bookkeeping and tax services provider, my main goal is to help individuals and businesses manage their finances in an accurate and compliant manner. I offer a range of services such as tracking income and expenses, managing accounts payable and receivable, and reconciling bank statements. Additionally, I provide tax preparation and filing services, ensuring that my clients are able to minimize their tax liability and avoid costly penalties. By taking care of their financial needs, I allow my clients to focus on what they do best - running their business.</p>
                    </div>
                    <div className='services-left'>
                        {
                            services.map((service, i) => {
                                return (
                                    <div key={i} className="service-block">
                                        <h2 className='font2'>{service.Name}</h2>
                                        <img src={service.Img} className="service-img" />
                                        <p className='service-block-desc'>{service.Desc}</p>
                                        {
                                            service.id == serviceID ?
                                                <p className='service-block-deets border-top1'>{service.Details}</p> : <p></p>
                                        }
                                        {/* {
                                            service.id == serviceID ?
                                                <button className='service-btn no-border color2 bg1' onClick={() => { setServiceID(-1) }}>Show Less</button> :
                                                <button className='service-btn no-border color2 bg1' onClick={() => { setServiceID(service.id) }}>Show More</button>
                                        } */}

                                    </div>
                                )
                            })
                        }
                    </div>
                    
                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
