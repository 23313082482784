import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';

// 
import '../STYLESHEETS/Home6.css'
// 
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import logo from '../PHOTOS/stock.png'
import img1 from '../PHOTOS/MAIN/tax7.jpeg'
import img2 from '../PHOTOS/MAIN/tax1.jpeg'
// 
import { Link } from 'react-router-dom'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { c_helmet, c_mainURL, c_routes } from '../Constants';
import { FaShoppingCart } from 'react-icons/fa'
import { MdOutlineBusinessCenter } from 'react-icons/md';

export default function Home() {
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "Home", Views: 0 })
    }, [])
    return (
        <div className='main'>
            {c_helmet("Home", c_routes.home6)}
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="font1">
                <div className='home6-panel1'>
                    <div className='home6-split'>
                        <div className='home6-panel-text'>
                            <h1 className='font2' style={{lineHeight: '1.1em'}}><span style={{fontSize: '2em', letterSpacing: '-2px'}}>A&A Bookkeeping.</span><br/><br/><span className='color4'>Maximize your savings and minimize your stress with our expert bookkeeping and tax services!</span></h1>
                            <div className='home6-panel-flex'>
                                <p className='color3'>Bookkeeping and tax services can help small businesses stay organized and financially healthy. Our expert team can take care of the details and help maximize your savings while minimizing your stress.</p>
                                {/* <MdOutlineBusinessCenter className='home6-panel-icon color3' /> */}
                            </div>
                        </div>
                        <div className='home6-panel-img'>
                            <img src={img1} className='home6-img' />
                        </div>
                    </div>
                    <div className='divider'></div>
                    {/* <div className='divider'></div> */}
                    <div className='home6-split'>
                        <div className='home6-panel-img'>
                            <img src={img2} className='home6-img' />
                        </div>
                        <div className='home6-panel-text'>
                            <h1 style={{lineHeight: '1em'}} className='color4 font2'>Expert tax services to help you save money and stay compliant - trust us with your financial needs!</h1>
                            <div className='home6-panel-flex'>
                                <p className='color3'>Expert tax services to help individuals and businesses stay compliant and save money. Services include tax preparation, bookkeeping, accounting, and financial planning. Let us take the stress out of managing your taxes and finances.</p>
                                {/* <MdOutlineBusinessCenter className='home6-panel-icon color3' /> */}
                            </div>

                        </div>


                    </div>
                    {/* <div className='divider'></div> */}
                    <div className='break'></div>
                    <div className='home6-panel2'>
                        <h1 className='font2 color4'>Maximize Your Wealth with Our Top-notch Bookkeeping and Tax Services</h1>
                        <br />
                        <div className='home6-panel2-blocks'>
                            <div className='home6-panel2-block bg2'>
                                <div className=''>
                                    {/* <MdOutlineBusinessCenter className='home6-panel2-icon' /> */}
                                    <h2 className='font2'>Bookkeeping Services</h2>
                                </div>
                                <p>Stay on top of your finances with our expert bookkeeping services! Our team of skilled professionals will maintain accurate financial records for your business, so you can focus on what you do best. We'll ensure your financial data is organized and easily accessible, making tax filing and financial planning a breeze. </p>
                            </div>
                            <div className='home6-panel2-block bg2'>
                                <div className=''>
                                    {/* <MdOutlineBusinessCenter className='home6-panel2-icon' /> */}
                                    <h2 className="font2">Tax Preparation Services</h2>
                                </div>
                                <p>Don't let tax season stress you out! Our team of tax service providers are here to help. We'll assist you in preparing and filing your tax returns, staying up-to-date with all the latest tax laws and regulations to ensure compliance. Our goal is to help you maximize your deductions and minimize your tax liabilities, so you can keep more of your hard-earned money. </p>
                            </div>
                            <div className='home6-panel2-block bg2'>
                                <div className=''>
                                    {/* <MdOutlineBusinessCenter className='home6-panel2-icon' /> */}
                                    <h2 className="font2">Payroll Processing Services</h2>
                                </div>
                                <p>Let us handle your payroll processing, so you can focus on running your business. Our services include calculating wages, deducting taxes and other withholdings, and issuing accurate paychecks and pay stubs. We'll ensure that all payroll taxes are paid on time, giving you peace of mind and saving you time and hassle.</p>
                            </div>
                            <div className='home6-panel2-block bg2'>
                                <div className=''>
                                    {/* <MdOutlineBusinessCenter className='home6-panel2-icon' /> */}
                                    <h2 className="font2">Financial Reporting Services</h2>
                                </div>
                                <p>Get the insights you need to make informed business decisions with our expert financial reporting services. We'll help you analyze your financial performance and provide detailed reports, so you can identify areas for improvement and plan for the future. Our budgeting and forecasting services will help you stay ahead of the curve, making sure you're prepared for whatever comes your way.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br />
            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
